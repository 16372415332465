body, html {
    height: 100%;
    margin: 0;
  }
.background{
    background-color: rgb(254, 251, 224);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}